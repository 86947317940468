<template>
	<div>
		<s-crud
			title="Query Manager - Configuración"
			:config="config"
			@save="save($event)"
			edit
			add
			remove
			:filter="filter"
			search-input
			ref="crud"
			no-full
			height="auto"
			@rowSelected="rowSelected($event)"
		>
			<template v-slot:filter>
				<v-col>
					<v-row justify="center">
					</v-row>
				</v-col>
			</template>

			<template scope="props">
				<!--  <v-container class="pb-0"> -->
				<v-container>
					<v-row justify="center">
						

						<v-col cols="12" lg="3" md="4" class="s-col-form" >
							<s-select
								:items="optionList"
								item-value="OptID"
								item-text="OptName"
								label="Módulo"
								v-model="props.item.OptID"
							></s-select>
						</v-col>

						<v-col
							cols="12"
							class="s-col-form"
							justfy="center"
							style="margin: initial;"
							lg="3"
						>
							<s-text
								v-model="props.item.QryNameSp"
								label="Nombre Procedure"
							>
							</s-text>
						</v-col>

						<v-col
							cols="12"
							class="s-col-form"
							justfy="center"
							style="margin: initial;"
							lg="3"
						>
							<s-text
								v-model="props.item.QryNameReport"
								label="Nombre Reporte"
							>
							</s-text>
						</v-col>
						<v-col
							cols="12"
							class="s-col-form"
							justfy="center"
							style="margin: initial;"
							lg="3"
						>
							<s-select-definition
								label="Base de Datos"
								:def="1368"
								v-model="props.item.QryDatabaseName"
							></s-select-definition>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<s-text
								label="Parámetros"
								v-model="props.item.QryParamsArray"
							></s-text>
						</v-col>
						<!-- <v-col cols="3">
							<s-text
								label="Fecha Fin"
								v-model="props.item.QryDateEndNameParam"
							></s-text>
						</v-col> -->
					</v-row>
				</v-container>
			</template>

			<template v-slot:accion="{ row }">
				<v-btn
					color="success"
					@click="openModalParameter(row)"
					x-small
				>
					<i class="fas fa-paragraph"></i>
				</v-btn>
			</template>
			
		</s-crud>

		<v-dialog
			persistent
			v-model="dialogParameter"
			width="800"
		>
			<v-card>
				<s-toolbar
					label="Agregar Parametros"
					color="#BAB6B5"
					close
					@close="closeModalParameter()"
					add
					@add="add()"
					remove
					@removed="deleteParameter()"
				>

				</s-toolbar>
				<v-container>
					<v-row align="center" justify="center">
						<v-col cols="2">
							<s-text
								number
								v-model="parameter.CfdPosition"
								label="Posicion"
							></s-text>
						</v-col>
						<v-col cols="3">
							<s-select-definition
								label="Tipo de Parametro"
								:def="1238"
								v-model="parameter.TypeParameter"
							></s-select-definition>
						</v-col>
						<v-col cols="3">
							<s-text
								label="Nombre de Parametro"
								v-model="parameter.CfdParameterName"
							>	
							</s-text>
						</v-col>
						<v-col v-if="parameter.TypeParameter == 2" cols="2">
							<s-text
								number
								v-model="parameter.CfdDefID"
								label="Definición"
							></s-text>
						</v-col>
					</v-row>
					<v-row align="center" justify="center" style="margin-top: -15px">
						<v-col v-if="parameter.TypeParameter == 4" cols="4">
							<s-text
								v-model="parameter.CfdItemValue"
								label="Item-Value"
							></s-text>
						</v-col>
						<v-col v-if="parameter.TypeParameter == 4" cols="4">
							<s-text
								v-model="parameter.CfdItemText"
								label="Item-Text"
							></s-text>
						</v-col>
					</v-row>
					<v-row align="center" justify="center" style="margin-top: -15px">
						<v-col v-if="parameter.TypeParameter == 4" cols="8">
							<s-textarea
								v-model="parameter.CfdQuerySql"
								label="Consulta"
							></s-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-data-table
								:headers="headersParameter"
								:items="itemsParameter"
								v-model="selecteItemParameter"
								dense
								@click:row="rowSelectParameter($event)"
								item-key="CfdID"
							>
								<!-- <template v-slot:item.>
									<div>
										
									</div>
								</template> -->
							</v-data-table>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>


<script>
	import _service from "@/services/QueryManager/QryConfigurationService";
    import _sOption from "@/services/Security/OptionService.js";
	import _sQryConfigurationDetailService from "@/services/QueryManager/QryConfigurationDetailService";

	export default {

		data() {
			return {
				optionList: [],
				filter: { TypeArea: 0 },
				config: {
					model: {
						QryID: "ID",
						OptID: "int",
						accion: "string"
					},
					service: _service,
					headers: [
						{ text: "Parámetros", value: "accion" },
						{ text: "ID", value: "QryID" },
						{ text: "Módulo", value: "OptName" },
						{ text: "Nombre Query", value: "QryNameSp" },
						{ text: "Nombre Reporte", value: "QryNameReport" },
						{ text: "Database", value: "QryDatabaseNameName" },
						{ text: "Parámetros", value: "QryParamsArray" },
						
					]
				},
				dialogParameter: false,
				headersParameter: [
					{text: "Tipo Parametro", value:"TypeParameterName"},
					{text: "Nombre Parametro", value:"CfdParameterName"},
					{text: "Posición", value:"CfdPosition"},
					{ text: "Value", value: "CfdItemValue" },
					{ text: "Text", value: "CfdItemText" },
					{ text: "Consulta", value: "CfdQuerySql" },
				],
				itemsParameter: [],
				parameter: {},
				query: {},
				selecteItemParameter: []
			};
		},


        created () {

            this.initialize();

			
        },

		methods: {

			rowSelected(item){
				console.log("item", item);
				this.selecteItemParameter = [item];
			},

			initialize() {
                _sOption.list(1, this.$fun.getUserID()).then((r) => {
                    this.optionList = r.data;
                    console.log(this.optionList);
                });
            },

            save(item){
				if(item.QryNameSp == ""){
					this.$fun.alert("Ingrese nombre del query", "warning");
					return;
				}
				
				item.save();
            },

			openModalParameter(item)
			{
				this.dialogParameter = true;
				this.query = item;
				this.getDataParameter();
                console.log("🚀 ~ file: QryConfiguration.vue ~ line 236 ~ this.query", this.query)
			},


			closeModalParameter()
			{
				this.dialogParameter = false;
			},

			rowSelectParameter(item)
			{
				this.parameter = item;
			},

			add()
			{
				if(this.parameter.CfdParameterName == "")
				{
					this.$fun.alert("Ingrese nombre del parametro", "warning");
					return;
				}

				if(this.parameter.TypeParameter == null)
				{
					this.$fun.alert("Ingrese tipo de parametro", "warning");
					return;
				}

				this.parameter.QryID = this.query.QryID;
				this.parameter.SecStatus = 1;
				this.parameter.UsrCreateID = this.$fun.getUserID();
				this.parameter.UsrUpdateID = this.$fun.getUserID();
				this.parameter.CfdDefID = this.parameter.CfdDefID != null ?  this.parameter.CfdDefID : 0
                console.log("🚀 ~ file: QryConfiguration.vue ~ line 293 ~ this.parameter", this.parameter)

				
				
				_sQryConfigurationDetailService
				.save(this.parameter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Parametro agregado correctamente", "success");
						this.getDataParameter();
						this.parameter = {};
						return;
					}
				})
			},

			deleteParameter()
			{
				this.parameter.SecStatus = 0;
				this.parameter.UsrUpdateID = this.$fun.getUserID();
				_sQryConfigurationDetailService
				.save(this.parameter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Parametro eliminado correctamente", "success");
						this.getDataParameter();
						return;
					}
				})
			},

			getDataParameter()
			{
				_sQryConfigurationDetailService
				.list(this.query, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.itemsParameter = resp.data;
					}
				})
			}
		},

	};
</script>
